<template>
  <div class="wall">
    <div class="grid-container pt-10">
      <div class="grid-x">
        <div class="cell small-10 small-offset-1 text-center">
          <span class="lozenge">We're here to</span>&nbsp;
          <span class="transform-text">transform</span>&nbsp;
          <span class="lives-text">lives</span>&nbsp;
          <span class="wall-text">wall</span>
        </div>
      </div>
      <div class="grid-x pt-15 pb-10">
        <div class="cell small-1">
          <div
            v-if="teamCount > 24 && page > 0"
            class="pa-10 nav-arrow-box">
            <img
              @click="page -= 1"
              class="nav-arrow"
              src="../assets/arrow_left.png"
              alt="Prev" />
          </div>
        </div>
        <div class="cell small-10 text-center relative">
          <div class="grid-x">
            <div
              v-for="(member, index) in team"
              :key="index"
              class="cell small-4 medium-2">
              <div @click="chooseImage(index + 1)" class="wall-image-box">
                <img
                  class="wall-image"
                  :src="member.bw_image"
                  alt="" />
              </div>
            </div>
          </div>
          <div v-if="chosenImage > 0" class="overlay">
            <div class="grid-x">
              <div
              v-for="(member, indexCol) in team"
              :key="indexCol"
              class="cell small-4 medium-2">
                <div :class="{ active: chosenImage === indexCol + 1 }" class="wall-image-box">
                  <img class="wall-image" :src="member.colour_image" alt="" />
                </div>
              </div>
            </div>
          </div>
          <span
            v-if="chosenImage > 0"
            @click="chosenImage = 0"
            :class="closePosition"
            class="close">X</span>
          <div
            v-if="chosenImage > 0"
            class="team-info"
            :class="teamInfoPosition">
            <span class="name">{{ chosenMember.name }}</span>
            <span class="title">{{ chosenMember.title}}</span>
            <span class="statement">{{ chosenMember.statement }}</span>
            <span class="signature">{{ chosenMember.signature }}</span>
          </div>
        </div>
        <div class="cell small-1">
          <div
            v-if="teamCount > 24 && page < (pages - 1)"
            class="pa-10 nav-arrow-box">
            <img
              @click="page += 1"
              class="nav-arrow"
              src="../assets/arrow_right.png"
              alt="Next" />
          </div>
        </div>
      </div>
      <div class="grid-x grid-padding-x pb-20">
        <div class="cell small-12">
          <div>
            <p class="text-center">
              <strong>Every role</strong>
              in Amicus has a part to play in transforming lives.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="chosenImage > 0" class="pop-out-wrap">
      <span @click="chosenImage = 0" class="pop-out-close"></span>
      <div class="pop-out">
        <img class="pop-out-image" :src="chosenMember.image" alt="" />
        <span class="pop-out-name">{{ chosenMember.name }}</span>
        <span class="pop-out-title">{{ chosenMember.title}}</span>
        <span class="pop-out-statement">{{ chosenMember.statement }}</span>
        <span class="pop-out-signature">{{ chosenMember.signature }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../axios';

export default {
  name: 'Wall',
  data() {
    return {
      page: 0,
      pages: 1,
      teamCount: 0,
      chosenImage: 0,
      team: [
        {
          bw_image: '',
          colour_image: '',
          name: '',
          signature: '',
          statement: '',
          title: '',
        },
      ],
    };
  },
  computed: {
    chosenMember() {
      const member = {};
      member.name = this.team[this.chosenImage - 1].name;
      member.signature = this.team[this.chosenImage - 1].signature;
      member.statement = this.team[this.chosenImage - 1].statement;
      member.title = this.team[this.chosenImage - 1].title;
      member.image = this.team[this.chosenImage - 1].colour_image;
      return member;
    },
    teamInfoPosition() {
      const tr = [1, 2, 3, 7, 8, 9];
      const tl = [4, 5, 6, 10, 11, 12];
      const br = [13, 14, 15, 19, 20, 21];
      const bl = [16, 17, 18, 22, 23, 24];
      return {
        'top-left': tl.includes(this.chosenImage),
        'top-right': tr.includes(this.chosenImage),
        'bottom-right': br.includes(this.chosenImage),
        'bottom-left': bl.includes(this.chosenImage),
      };
    },
    closePosition() {
      const w = window.innerWidth;
      if (w > 639) {
        return {
          'top-right': this.chosenImage === 6,
        };
      }
      return {
        'top-right': this.chosenImage === 3,
      };
    },
  },
  watch: {
    page() {
      this.getTeamMembers();
    },
  },
  methods: {
    chooseImage(image) {
      this.chosenImage = image;
    },
    getTeamMembers() {
      axios.get(`/teamMembers/fetch/${this.page}.json`)
        .then((response) => {
          this.team = response.data.teamMembers;
          this.teamCount = response.data.total;
          this.pages = response.data.pages;
        })
        .catch(() => {
          this.failed = true;
        });
    },
  },
  mounted() {
    this.getTeamMembers();
  },
};
</script>

<style lang="scss" scoped>
.wall {
  background-image: url('../assets/butterfly.png');
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 30% auto;
}
.lozenge {
  position: relative;
  top: -8px;
  display: inline-block;
  background-image: linear-gradient(to right, #243171 , #00b0bc);
  padding: 0 20px;
  border-radius: 20px;
  color: #FFF;
  font-family: 'co_headlinebold';
  text-transform: uppercase;
  font-size: 18px;
  height: 30px;
  line-height: 28px;
  letter-spacing: 3px;
}
.transform-text {
  font-family: 'co_headlinebold';
  font-size: 50px;
  color: #ed8423;
  line-height: 0.9;
}
.lives-text {
  font-family: 'co_headlinebold';
  font-size: 50px;
  color: #ad1f25;
  line-height: 0.9;
}

.wall-text {
  font-family: 'co_headlinebold';
  font-size: 50px;
  color: #243171;
  background: -webkit-linear-gradient(left, #006094 , #00b2bd);
  background: -o-linear-gradient(right, #006094, #00b2bd);
  background: -moz-linear-gradient(right, #006094, #00b2bd);
  background: linear-gradient(to right, #006094, #00b2bd);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  line-height: 0.9;
}
.nav-arrow-box {
  position: relative;
  top: 50%;
}
.nav-arrow {
  cursor: pointer;
}
.wall-image-box {
  background-color: #FFF;
  padding: 2px;
  cursor: pointer;
  &:hover,
  &:focus {
    padding: 4px;
    background-image: linear-gradient(to right, #eb8223, #ad1e25);
  }
}
.relative {
  position: relative;
}
.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.7);
  // pointer-events: none;

  .wall-image-box {
    opacity: 0;

    &.active {
      opacity: 1;
      padding: 4px;

      &:hover {
        background-color: #FFF;
        background-image: none;
      }
    }
  }
}
.close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  background-image: url('../assets/close_button.png');
  background-repeat: no-repeat;
  background-size: 35px auto;
  font-size: 0;
  cursor: pointer;
  z-index: 3;

  &.top-right {
    right: auto;
    left: 10px;
  }
}
.team-info {
  pointer-events: none;
  position:absolute;
  top: 20px;
  left: 60px;
  z-index: 2;
  text-align: left;
  max-width: 40%;
  &.top-right {
    top: 30px;
    left: 52%;
  }
  &.bottom-right {
    top: 30%;
    left: 52%;
  }
  &.bottom-left {
    top: 30%;
  }
}
@media all and (max-width: 1200px) {
  .team-info {
    &.bottom-left {
      top: 20px;
    }
    &.bottom-right {
      top: 20px;
    }
  }
}
.name {
  color: #cdc86d;
  display: block;
  font-weight: bold;
  font-size: 20px;
}
.title {
  color: #cdc86d;
  display: block;
  font-size: 18px;
}
.statement {
  display: block;
  color: #FFF;
  font-size: 18px;
  padding-top: 20px;
}
.signature {
  display: inline-block;
  padding-top: 30px;
  color: #FFF;
  font-family: 'honey_scriptlight';
  font-size: 80px;
  transform: rotate(-15deg);
  line-height: 0.5;
}

@media all and (max-width: 1200px) {
  .name {
    font-size: 16px;
  }
  .title {
    font-size: 14px;
  }
  .statement {
    font-size: 14px;
  }
  .signature {
    font-size: 30px;
  }
}

@media all and (max-width: 910px) {
  .overlay,
  .close,
  .team-info {
    display: none;
  }
}

.pop-out-wrap {
  position:fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  background-color: rgba(0,0,0,0.6);
}

.pop-out {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  z-index: 4;
  padding: 20px 20px 60px 20px;
  background-color: #FFF;
  border-radius: 5px;
}

.pop-out-close {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 15;
  width: 30px;
  height: 30px;
  background-color: #333;
  background-image: url('../assets/close_button.png');
  background-size: 26px auto;
  background-repeat: no-repeat;
  border-radius: 50%;
  background-position: center center;
  cursor: pointer;
}

.pop-out-name {
  color: #333;
  display: block;
  font-weight: bold;
  font-size: 20px;
}
.pop-out-title {
  color: #333;
  display: block;
  font-size: 18px;
}
.pop-out-statement {
  display: block;
  color: #333;
  font-size: 18px;
  padding-top: 20px;
}
.pop-out-signature {
  display: inline-block;
  padding-top: 30px;
  color: #333;
  font-family: 'honey_scriptlight';
  font-size: 80px;
  transform: rotate(-15deg);
  line-height: 0.5;
}

.pop-out-image {
  max-width: 38%;
  margin-left: 20px;
  float: right;
}

@media all and (min-width: 910px) {
  .pop-out-wrap,
  .pop-out {
    display: none;
  }
}
</style>
